import Vue from 'vue'
import App from './App.vue'
import {fpjsPlugin} from '@fingerprintjs/fingerprintjs-pro-vue-v2';

Vue.config.productionTip = false

const app = new Vue({
    render: h => h(App),
})
const apiKey = process.env.VUE_APP_SUBSCRIPTION_API_KEY
Vue.use(fpjsPlugin, {
    loadOptions: {
        endpoint: '/fpjs-worker/visitorId',
        scriptUrlPattern: '/fpjs-worker/agent?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>',
        apiKey,
    },
});
app.$mount('#app')
