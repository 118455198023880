<template>
  <div id="app">
    <p>Visitor Id is {{ visitorId || 'undefined' }}</p>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: 'App',
  data: () => (
      {
        visitorId: undefined,
      }
  ),
  methods: {
    async getVisitorData() {
      const visitorData = await this.$fpjs.getVisitorData({
        extendedResult: true
      });

      this.visitorId = visitorData.visitorId
    }
  },
  mounted() {
    this.getVisitorData()
  }
})
</script>

<style>
#app {}
</style>
